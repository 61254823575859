import React from "react";
import RequestApproval from "../components/Epk/Input/requestApproval";

function TestApproval() {
  return (
    <div>
      <RequestApproval />
    </div>
  );
}
export default TestApproval;
